import { Component, } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  username;
  password;
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
  }

  login() {
    this.auth.isLogin(this.username, this.password).then(result => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'เข้าสู่ระบบสำเร็จ',
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        this.router.navigate(['/dashboard']);
      })
    }).catch(err => {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'กรุณากรอกอีเมลหรือรหัสผ่านให้ถูกต้อง',
        showConfirmButton: false,
        timer: 1000
      })
    });
  }
}
