import { Injectable } from '@angular/core';
import { reject } from 'q';
import { environment } from '../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  isLogin(username, password) {

    return new Promise((res, rej) => {
      this.http.post(environment.environment.API_BACKEND + '/v1/login', { username: username, password: password }).subscribe(resp => {
        if (resp['status'] === 200) {
          this.payload(resp['data'].token)
          res(true);
        } else {
          rej(false);
        }
      });
    });
  }

  payload(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'lcf ' + token
      })
    };
    this.http.get(environment.environment.API_BACKEND + '/v1/login/payload', httpOptions).subscribe(res => {
      res['token'] = token,
      this.setLocalStroageUser(res);
    });
  }

  setLocalStroageUser(data) {
    localStorage.setItem('user', JSON.stringify(data));
  }

  getLocalStroageUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLogOut() {
    localStorage.clear();
  }
}
