import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  listNotify() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'lcf ' + this.auth.getLocalStroageUser().token
      })
    };
    return this.http.get(environment.environment.API_BACKEND + '/v1/notify', httpOptions)
  }
}
