import { Component, Input, OnInit } from '@angular/core';
import { navItems } from './../../_nav';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Profile } from 'selenium-webdriver/firefox';
import { NotifyService } from '../../services/notify.service';

import Swal from 'sweetalert2'
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public navItems;
  public navItems1;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  profile_user;

  public listNoti: []
  listNotis: any

  constructor(
    private auth: AuthService,
    private router: Router,
    private noti: NotifyService
  ) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  ngOnInit() {
    this.profile_user = this.auth.getLocalStroageUser();
    this.navItems = navItems.filter(item => {
      const check_role = [];
      this.profile_user.roles.forEach(element => {
        check_role.push(item.role.includes(element));
      });
      return check_role.includes(true);
    });
    this.getNoti()
  }

  comingSoon() {
    Swal.fire({
      icon: 'error',
      title: 'อุปส์...',
      text: 'กำลังพัฒนา!'
    })
  }

  subStr(text: string) {
    if (text.length > 40) return text.substr(0, 50) + '...'
    else return text
    
  }

  getNoti() {
    var ll = []
    this.noti.listNotify().subscribe(resp => {
      if (resp['status'] === 200) {
        this.listNoti = resp['data']
        this.listNoti.forEach(el => {
          ll.push({
            event_type: el['event_type'],
            title: el['title'],
            detail: this.subStr(el['detail']),
            user_name: el['user_name'],
            created_at: el['created_at']
          })
        })
        this.listNotis = ll
        // console.log(ll)
      }
    })
  }

  logOut() {
    Swal.fire({
      title: 'ต้องการออกจากระบบ?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ออกจากระบบ!',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        this.auth.isLogOut();
        this.router.navigate(['/login']);
      }
    });
  }
}
